import React, {
  forwardRef,
  useState,
  useImperativeHandle,
  useEffect,
  useRef,
  useMemo,
} from "react";
import { useTranslation } from "react-i18next";
import {
  SheetTable,
  Table as TableType,
  TableView,
  TableType as TableTypeEnum,
  TableFilters,
} from "../types/Table";
import { Modal, Select, message, Button } from "antd";
import TableFieldsList from "../components/TableFieldsList";
import { getTableListApi } from "../api/TableApi";
import { PlusCircleOutlined } from "@ant-design/icons";
import TableDataFilterModal from "../components/TableDataFilterModal";

type TableViewFormData = {
  selectedTable?: TableType;
  selectedFields?: string[];
  filters?: TableFilters;
};

const FillDataFromTableModal = forwardRef(
  (
    props: {
      table?: SheetTable;
      submitCallback?: (data: TableView) => void;
    },
    ref
  ) => {
    const { t } = useTranslation();
    const [basetables, setBasetables] = useState<TableType[]>([]);
    const [showItem, setShowItem] = useState<TableView | undefined>();
    const [viewData, setViewData] = useState<TableViewFormData>({});
    const tableDataFilterModalRef: any = useRef();

    useEffect(() => {
      getTableListApi({
        tableType: TableTypeEnum.PRIMARY,
        page_size: 1000,
      }).then((res) => {
        if (res.success) {
          setBasetables(res.data);
        }
      });
    }, []);

    useEffect(() => {
      if (!!showItem?.parent_table) {
        setViewData({
          selectedTable: basetables.find(
            (table) => table.id === showItem.parent_table
          ),
          selectedFields: showItem?.columns || [],
          filters: showItem?.filters,
        });
      } else {
        setViewData({});
      }
      // eslint-disable-next-line
    }, [showItem]);

    useImperativeHandle(ref, () => ({
      show: (item: TableView) => {
        setShowItem(item);
      },
      close: handleCancel,
    }));

    const handleCancel = () => {
      setShowItem(undefined);
    };

    const handleTableChange = (key: number) => {
      setViewData({
        selectedTable: basetables.find((table) => table.id === key),
      });
    };

    const handleAddFilters = () => {
      tableDataFilterModalRef.current &&
        tableDataFilterModalRef.current.show(
          viewData?.filters || {},
          viewData?.selectedTable?.meta?.fields || []
        );
    };

    const tableDataFilterModal = useMemo(() => {
      return (
        <TableDataFilterModal
          ref={tableDataFilterModalRef}
          submitCallback={(filters: TableFilters) => {
            setViewData((data) => ({
              ...data,
              filters: filters,
            }));
            tableDataFilterModalRef.current &&
              tableDataFilterModalRef.current.close();
          }}
        />
      );
    }, [setViewData]);

    const handleSubmit = () => {
      if (
        !viewData.selectedTable?.id ||
        !viewData.selectedFields ||
        viewData.selectedFields.length < 1
      ) {
        message.error(t("dataset.table_views.select_table_field"));
      } else {
        props.submitCallback &&
          props.submitCallback({
            ...showItem,
            parent_table: viewData.selectedTable?.id,
            parent_table_data: viewData.selectedTable,
            filters: viewData.filters,
            columns: viewData.selectedFields,
            task_id: showItem?.task_id,
          });
      }
    };

    return (
      <>
        {tableDataFilterModal}
        <Modal
          centered
          width={700}
          title={t("dataset.data_setup")}
          open={!!showItem}
          onCancel={handleCancel}
          onOk={handleSubmit}
          cancelText={t("common.cancel")}
          okText={t("common.save")}
        >
          <Select
            showSearch
            style={{ width: "100%", marginBottom: "1em" }}
            placeholder={t("dataset.table_views.select_table")}
            options={(basetables || []).map((table) => ({
              label: `${table.name}`,
              value: table.id,
            }))}
            value={viewData.selectedTable?.id}
            onChange={handleTableChange}
          />
          <TableFieldsList
            fields={viewData.selectedTable?.fields}
            rowSelection={{
              selectedRowKeys: viewData?.selectedFields || [],
              onChange: (selectedRowKeys: string[]) => {
                setViewData((data) => ({
                  ...data,
                  selectedFields: selectedRowKeys,
                }));
              },
              columnWidth: 50,
              fixed: true,
            }}
          />
          {!!viewData.selectedTable && (
            <Button
              type="link"
              icon={<PlusCircleOutlined />}
              onClick={handleAddFilters}
              style={{ marginTop: "1em" }}
            >
              {!!viewData?.filters?.items &&
              viewData?.filters?.items?.length > 0
                ? t("table_filter.count_filters", {
                    count: viewData?.filters?.items?.length,
                  })
                : t("table_filter.add_filters")}
            </Button>
          )}
        </Modal>
      </>
    );
  }
);

export default FillDataFromTableModal;
