import axios from "axios";
import Qs from "qs";
import { refreshNotification } from "../store/account";
import store from "../store";
import i18next from "../config/i18n";
import { getConfig } from "../config/config";
import AuthService from "../utils/AuthService";
import GlobalLoader from "../components/GlobalLoading";
const config = getConfig();

export interface responseData {
  success: boolean;
  status: number;
  data: any;
  pagination?: {
    count?: number | null;
    next?: number | null;
    previous?: number | null;
  };
  message?: any;
  errors?: any;
  code?: any;
  statusText: any;
  headers: any;
  config: any;
}

const API = axios.create({
  baseURL: config.apiBaseUrl,
  headers: { "Content-Type": "application/json;charset=UTF-8" },
  paramsSerializer: function (params) {
    return Qs.stringify(params, { indices: false });
  },
  // timeout: 10000,
});

API.interceptors.request.use(
  (config) => {
    if (config.method !== "get") {
      if (!config.headers.skipLoading) {
        GlobalLoader.show();
      } else {
        config.skipLoading = true;
        delete config.headers.skipLoading;
      }
    }
    return config;
  },
  (error) => {
    if (error.config.method !== "get" && !error.config.skipLoading) {
      GlobalLoader.hide();
    }
    return Promise.reject(error);
  }
);

API.interceptors.response.use(
  (res): responseData => {
    if (res.config.method !== "get") {
      if (!res.config.skipLoading) {
        GlobalLoader.hide();
      }
      store.dispatch(refreshNotification());
    }
    return {
      success: res.status >= 200 && res.status < 300,
      status: res.status,
      data: !!res.data?.results ? res.data?.results : res.data,
      statusText: res.statusText,
      headers: res.headers,
      config: res.config,
      pagination: !!res.data?.count
        ? {
            count: res.data?.count,
            next: res.data?.next,
            previous: res.data?.previous,
          }
        : undefined,
      // pagination: JSON.parse(res.headers['x-pagination'] || '{}')
    };
  },
  (error) => {
    if (error.config.method !== "get" && !error.config.skipLoading) {
      GlobalLoader.hide();
    }
    const status_code = error.response ? error.response.status : 500;
    if (status_code === 401) {
      AuthService.login(
        !!config.authIdpHint
          ? {
              idpHint: config.authIdpHint,
            }
          : {
              locale: i18next?.language === "en" ? "en" : "zh-CN",
            }
      );
      return { success: false, status: status_code, message: "unauthorized" };
    }
    return {
      code: status_code,
      status: status_code,
      success: false,
      message:
        error.response && status_code !== 500
          ? error.response.data
            ? error.response.data.message ||
              error.response.data.status ||
              error.response.data
            : error.response
          : error.response?.data || "网络错误，请稍后重试",
      errors: error.response?.data?.errors,
    };
  }
);

const initToken = (token: string) => {
  // store token to local storage
  localStorage.setItem("Token", JSON.stringify(token));
  API.defaults.headers.common["Authorization"] = `Bearer ${token}`;
};

export { API, initToken };
