import { User } from "./User";
import { Task } from "./Dataset";

export type SheetTable = {
  id?: number;
  identifier?: string;
  name?: string;
  editable?: boolean;
  fields?: TableField[];
  meta?: TableMeta;
  table_type?: string;
  data?: any;
  filtered_data?: any;
  all_data?: any;
  tasks?: any;
  owner?: string;
  sync_config?: {
    manual?: boolean;
  };

  is_admin?: boolean;
  current_task?: Task;
  role?: string;
  origin_table_name?: string;

  columns?: string[];
  active?: boolean;
};

export type Table = {
  id?: number;
  index?: number;
  index_id?: number;
  identifier?: string;
  name?: string;
  description?: string;
  owner?: string;
  owner_user?: User;
  meta?: TableMeta;
  created_at?: string;
  updated_at?: string;
  table_type?: string;
  fields?: TableField[];
  editable?: boolean;
  temp_id?: string; // temp id for creating table
  role?: string;
  sync_config?: {
    enabled?: boolean;
    manual?: boolean;
    connection_id?: string;
  };
};

export enum TableType {
  PRIMARY = "PRIMARY",
  COMMON = "COMMON",
}

export type TableMeta = {
  id?: string;
  name?: string;
  description?: string;
  fields?: TableField[];
  version?: number;
};

export type TableField = {
  id?: number;
  index?: number;
  index_id?: number;
  identifier?: string;
  name?: string;
  type?: string;
  is_pk?: boolean;
  is_unique?: boolean;
  is_nullable?: boolean;
  is_partition_key?: boolean;
  is_filterable?: boolean;
  fk_to?: number;
  fk_table_name?: string;
  fk_field?: string;
  fk_literal?: string;
  fk_enums?: TableFieldFKEnum[];
  readonly?: boolean;
  was_referenced?: false;
  validation_rules?: any;
  fk_enums_mapping?: any;
};

export type TableFieldFKEnum = {
  field: any;
  literal: any;
};

export enum TableFieldType {
  TEXT = "TEXT",
  NUMBER = "NUMBER",
  DATE = "DATE",
  BOOLEAN = "BOOLEAN",
  REFERENCE = "REFERENCE",
}

// map from status to a title
export const fieldTypeToTitle = {
  [TableFieldType.TEXT]: "文本",
  [TableFieldType.NUMBER]: "数字",
  [TableFieldType.DATE]: "日期",
  [TableFieldType.BOOLEAN]: "布尔值",
  [TableFieldType.REFERENCE]: "引用",
};

export type TableFilters = {
  type?: string;
  items?: TableFilterItem[];
  applied?: boolean;
};

export type TableFilterItem = {
  field?: string;
  type?: string;
  value?: any;
  date?: any;
};

export type TableView = {
  id?: number;
  index?: number;
  index_id?: number;
  parent_table?: number;
  columns?: string[];
  parent_table_data?: Table;
  data?: any;
  temp_id?: string;
  filters?: TableFilters;

  task_id?: number;
  parent_table_name?: string;
};

export type TablePartition = {
  id?: number;
  partition_identifier?: string;
  table?: Table;
};

export type DataRowValidation = {
  message: string;
  field: string;
  index: number;

  col_index?: number;
};

export type TableMembership = {
  id?: number;
  user?: string;
  table?: number;
  role?: string;
  user_info?: User;

  delete?: boolean;
  items?: TableItem[];
};

export type TableItem = {
  id?: number;
  temp_id?: string;
  content_type?: string;
  object_id?: number;
  index?: number;
  object?: any;
};

export enum Role {
  ADMIN = "ADMIN",
  VISITOR = "VISITOR",
}
