import React, { useEffect, useState } from "react";
import NavHyperLink from "../../components/NavHyperLink";
import {
  Button,
  Table,
  Modal,
  message,
  Form,
  Input,
  Checkbox,
  Select,
  Typography,
} from "antd";
import type { ColumnsType } from "antd/es/table";
import type { Pipeline } from "../../types/Pipeline";
import viewUtils from "../../utils/viewUtils";
import JsonEditor from "../../components/JsonEditor";
import { useTranslation } from "react-i18next";
import { Workspace } from "../../types/Workspace";
import {
  createPipelineAppsApi,
  deletePipelineAppsApi,
  getSystemPipelineAppsApi,
  registerOrUpdatePipelineAppsApi,
  updatePipelineAppsApi,
} from "../../api/IntegrationsApis";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { getAllWorkspacesApi } from "../../api";
import GlobalLoader from "../../components/GlobalLoading";

// cannot get the value setting by form.setFieldsValue from form.getFieldsValue
let manifest: any;

const PipelineManagePage = () => {
  const { t, i18n } = useTranslation();
  const [pipelines, setPipelines] = useState<Pipeline[]>([]);
  const [total, setTotal] = useState(0);
  const [modalPipeline, setModalPipeline] = useState<Pipeline | null>();
  const [filter, setFilter] = useState({ page: 1, page_size: 10 });
  const [form] = Form.useForm();

  useEffect(() => {
    getSystemPipelineAppsApi({
      ...filter,
    }).then((res) => {
      if (res.success) {
        setPipelines(res.data);
        setTotal(res.pagination?.count || 0);
      }
    });
  }, [filter]);

  const showModal = (pipeline: Pipeline) => {
    form.resetFields();
    form.setFieldsValue({
      id: pipeline.id,
      active: pipeline.active || true,
      is_private: pipeline.is_private || false,
      private_workspace_ids: pipeline.is_private
        ? pipeline.private_workspace_ids
        : [],
    });
    setModalPipeline(pipeline);
  };

  const PipelineFormModal = () => {
    const [workspaces, setWorkspaces] = useState([] as Workspace[]);

    useEffect(() => {
      if (!modalPipeline) {
        return;
      }
      if (!!modalPipeline.id) {
        getAllWorkspacesApi().then((res) => {
          if (res.success) {
            setWorkspaces(res.data);
          }
        });
        manifest = modalPipeline.manifest;
      }
    }, [modalPipeline]);

    const handleJsonChange = (e: any) => {
      manifest = e.jsObject;
    };

    const handleOk = async () => {
      GlobalLoader.show();

      // wait for json format finished
      setTimeout(() => {
        save();
        GlobalLoader.hide();
      }, 1000);
    };

    const save = async () => {
      const pipelineData = { ...form.getFieldsValue(true) };

      if (!!pipelineData.id) {
        pipelineData.manifest = manifest;
        const response = await updatePipelineAppsApi({
          pipelineId: pipelineData.id,
          data: pipelineData,
        });
        if (response.success) {
          message.success(
            t("system_manage.pipeline_manage.tip.update", {
              status: t("common.success"),
            })
          );
          setFilter((data) => ({ ...data }));
          setModalPipeline(null);
        } else {
          const key = `system_manage.pipeline_manage.tip.${response.message.detail}`;
          if (i18n.exists(key)) {
            message.error(t(key));
          } else {
            message.error(
              t("system_manage.pipeline_manage.tip.update", {
                status: t("common.fail"),
              })
            );
          }
        }
      } else {
        const response = await registerOrUpdatePipelineAppsApi(pipelineData);
        if (response.success) {
          message.success(
            t("system_manage.pipeline_manage.tip.add", {
              status: t("common.success"),
            })
          );
          setFilter((data) => ({ ...data }));
          setModalPipeline(null);
        } else {
          const key = `system_manage.pipeline_manage.tip.${response.message.detail}`;
          if (i18n.exists(key)) {
            message.error(t(key));
          } else {
            message.error(
              t("system_manage.pipeline_manage.tip.add", {
                status: t("common.fail"),
              })
            );
          }
        }
      }
    };

    const handleCancel = () => {
      setModalPipeline(null);
    };

    if (!modalPipeline) {
      return null;
    }
    return (
      <Modal
        title={
          !!modalPipeline!.id
            ? `${t("common.edit")}${t(
                "system_manage.pipeline_manage.pipeline"
              )}`
            : `${t("common.upsert")}${t(
                "system_manage.pipeline_manage.pipeline"
              )}`
        }
        okText={!!modalPipeline!.id ? t("common.save") : t("common.upsert")}
        cancelText={t("common.cancel")}
        open={!!modalPipeline}
        onOk={handleOk}
        onCancel={handleCancel}
        width={800}
      >
        <Form
          name="app form"
          form={form}
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 19 }}
          autoComplete="off"
        >
          {!modalPipeline!.id && (
            <>
              <Form.Item
                label={t("system_manage.pipeline_manage.image")}
                name="image"
                rules={[{ required: true, message: "Please input the image" }]}
              >
                <Input />
              </Form.Item>
            </>
          )}
          {!!modalPipeline!.id && (
            <>
              <Form.Item
                label={t("system_manage.pipeline_manage.id")}
                name="id"
                hidden
              >
                <Input disabled />
              </Form.Item>
              <Form.Item label={t("system_manage.pipeline_manage.manifest")}>
                <JsonEditor
                  id="manifest_editor"
                  onChange={handleJsonChange}
                  height="20em"
                  value={modalPipeline.manifest}
                />
              </Form.Item>
              <Form.Item
                label={t("system_manage.pipeline_manage.active")}
                name="active"
                valuePropName="checked"
                hidden={!modalPipeline!.id}
              >
                <Checkbox />
              </Form.Item>
              <Form.Item
                label={t("system_manage.pipeline_manage.is_private")}
                name="is_private"
              >
                <Select
                  options={[
                    {
                      label: t("system_manage.pipeline_manage.public"),
                      value: false,
                    },
                    {
                      label: t("system_manage.pipeline_manage.private"),
                      value: true,
                    },
                  ]}
                />
              </Form.Item>
              <Form.Item
                noStyle
                shouldUpdate={(prevValues, curValues) =>
                  prevValues?.is_private !== curValues?.is_private
                }
              >
                {() => {
                  if (!form.getFieldValue("is_private")) {
                    return <></>;
                  }
                  return (
                    <Form.Item
                      label={t(
                        "system_manage.pipeline_manage.private_workspaces"
                      )}
                      name="private_workspace_ids"
                    >
                      <Select
                        allowClear
                        mode="multiple"
                        options={workspaces.map((item) => ({
                          label: `${item.name}(${(item.owner || {}).name})`,
                          value: item.id,
                        }))}
                        filterOption={(
                          input: string,
                          option?: { label: string; value: number }
                        ) => {
                          return option
                            ? (option.label ?? "").includes(input)
                            : false;
                        }}
                      />
                    </Form.Item>
                  );
                }}
              </Form.Item>
            </>
          )}
        </Form>
      </Modal>
    );
  };

  const handleDelete = (pipeline: Pipeline) => {
    Modal.confirm({
      title: `${t("common.delete")}${t(
        "system_manage.pipeline_manage.pipeline"
      )}`,
      icon: <ExclamationCircleOutlined />,
      content: t("system_manage.pipeline_manage.confirm_delete", {
        pipeline_name:
          i18n.resolvedLanguage === "en"
            ? pipeline?.manifest?.display_name?.en
            : pipeline?.manifest?.display_name?.zh,
      }),
      onOk: () => {
        if (!pipeline.id) {
          return;
        }
        deletePipelineAppsApi({ pipelineId: pipeline.id }).then((res) => {
          if (res.success) {
            message.success(
              t("common.result_status", {
                title: t("common.delete"),
                status: t("common.success"),
              })
            );
            setFilter((data) => ({ ...data }));
          } else {
            message.error(
              t("common.result_status", {
                title: t("common.delete"),
                status: t("common.fail"),
              })
            );
          }
        });
      },
      cancelText: t("common.cancel"),
      okText: t("common.ok"),
    });
  };

  const handlePageChange = (page: number, page_size: number) => {
    setFilter((data) => ({ ...data, page: page, page_size: page_size }));
  };

  const columns: ColumnsType<Pipeline> = [
    {
      title: "执行流程ID",
      key: "id",
      width: 200,
      render: (r) => (
        <span style={{ wordBreak: "break-all" }}>{r?.manifest?.id}</span>
      ),
    },
    {
      title: "执行流程名称",
      key: "name",
      width: 200,
      render: (r) =>
        i18n.resolvedLanguage === "en"
          ? r?.manifest?.display_name?.en
          : r?.manifest?.display_name?.zh,
    },
    {
      title: "执行流程描述",
      key: "description",
      width: 400,
      render: (r) => {
        const _description =
          typeof r?.manifest?.description === "string"
            ? r?.manifest?.description
            : i18n.resolvedLanguage === "en"
            ? r?.manifest?.description?.en
            : r?.manifest?.description?.zh;
        return (
          <Typography.Paragraph
            ellipsis={{
              rows: 2,
              tooltip: {
                title: _description,
                placement: "bottomLeft",
                overlayClassName: "description_tooltip",
              },
            }}
            className="mb-0"
          >
            {_description}
          </Typography.Paragraph>
        );
      },
    },
    {
      title: "使用空间",
      key: "workspace",
      width: 200,
      render: (r) => (
        <Typography.Paragraph
          ellipsis={{
            rows: 2,
            tooltip: {
              title: r?.private_workspace_names,
              placement: "bottomLeft",
              overlayClassName: "description_tooltip",
            },
          }}
          className="mb-0"
        >
          {(r?.is_private && r?.private_workspace_names) || "-"}
        </Typography.Paragraph>
      ),
    },
    {
      title: "类型",
      key: "is_private",
      width: 100,
      render: (r) =>
        r.is_private
          ? t("system_manage.pipeline_manage.private")
          : t("system_manage.pipeline_manage.public"),
    },
    {
      title: "状态",
      key: "active",
      width: 100,
      render: (r) =>
        r.active
          ? t("system_manage.pipeline_manage.active_true")
          : t("system_manage.pipeline_manage.active_false"),
    },
    {
      title: "创建时间",
      key: "created_at",
      width: 200,
      render: (r) => viewUtils.utcToLocal(r.created_at),
    },
    {
      key: "actions",
      title: "操作",
      fixed: "right",
      render: (r: Pipeline) => (
        <div style={{ display: "flex" }}>
          <Button size="small" type="link" onClick={() => showModal(r)}>
            编辑
          </Button>
          <Button
            size="small"
            type="link"
            danger
            onClick={() => handleDelete(r)}
          >
            删除
          </Button>
        </div>
      ),
    },
  ];

  return (
    <>
      <PipelineFormModal />
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <NavHyperLink items={[{ title: "执行流程管理" }]} />
        <Button type="primary" onClick={() => showModal({})}>
          {t("common.upsert")}
        </Button>
      </div>
      <div style={{ marginTop: "1.5em" }}>
        <Table
          className="common-table"
          rowKey={(r) => r.id || ""}
          scroll={{ x: "max-content", scrollToFirstRowOnChange: true }}
          columns={columns}
          dataSource={pipelines || []}
          pagination={{
            showQuickJumper: true,
            showTotal: (total) => `共 ${total} 条`,
            current: filter.page,
            pageSize: filter.page_size,
            total: total,
            onChange: handlePageChange,
            showSizeChanger: true,
            pageSizeOptions: [10, 20, 50],
            style: { margin: "1em" },
          }}
        />
      </div>
    </>
  );
};

export default PipelineManagePage;
