import { API, responseData } from "./BaseApi";

export const checkFlowApiTokenApi = (data: {
  flowApiToken: string;
  flowWorkspaceId: string;
}): Promise<boolean> => {
  return new Promise((resolve, reject) => {
    API.post<responseData, responseData>(`flow/check-api-token/`, {
      flow_api_token: data.flowApiToken,
      flow_workspace_id: data.flowWorkspaceId,
    })
      .then((response) => {
        resolve(response.success);
      })
      .catch(() => {
        resolve(false); // 用resolve代替return，确保返回Promise
      });
  });
};

export const getFlowAppsApi = (data: {
  flowApiToken: string;
  flowWorkspaceId: string;
  page?: number; // 新增分页参数
  pageSize?: number; // 新增分页参数
}) => {
  let queryParams = new URLSearchParams();
  queryParams.append("flow_api_token", data.flowApiToken?.toString());
  queryParams.append("flow_workspace_id", data.flowWorkspaceId?.toString());
  if (data.page) queryParams.append("page", data.page?.toString());
  if (data.pageSize) queryParams.append("page_size", data.pageSize?.toString());

  return API.get<responseData, responseData>(
    `flow/get-app-list/?${queryParams.toString()}`
  );
};

export const getAllFlowAppsApi = (data: { flowApiToken: string }) => {
  let queryParams = new URLSearchParams();
  queryParams.append("flow_api_token", data.flowApiToken.toString());

  return API.get<responseData, responseData>(
    `flow/get-all-app-list/?${queryParams.toString()}`
  );
};

export const getFlowSessionsApi = (data: {
  flowApiToken: string;
  flowWorkspaceId: string;
  flowAppId: string;
  page?: number; // 新增分页参数
  pageSize?: number; // 新增分页参数
}) => {
  let queryParams = new URLSearchParams();
  queryParams.append("flow_api_token", data.flowApiToken?.toString());
  queryParams.append("flow_workspace_id", data.flowWorkspaceId?.toString());
  queryParams.append("flow_app_id", data.flowAppId?.toString());
  if (data.page) queryParams.append("page", data.page?.toString());
  if (data.pageSize) queryParams.append("page_size", data.pageSize?.toString());

  return API.get<responseData, responseData>(
    `flow/get-session-list/?${queryParams.toString()}`
  );
};

export const getFlowInstancesApi = (data: {
  flowApiToken: string;
  flowWorkspaceId: string;
  flowAppId: string;
  page?: number; // 新增分页参数
  pageSize?: number; // 新增分页参数
}) => {
  let queryParams = new URLSearchParams();
  queryParams.append("flow_api_token", data.flowApiToken?.toString());
  queryParams.append("flow_workspace_id", data.flowWorkspaceId?.toString());
  queryParams.append("flow_app_id", data.flowAppId?.toString());
  if (data.page) queryParams.append("page", data.page?.toString());
  if (data.pageSize) queryParams.append("page_size", data.pageSize?.toString());
  return API.get<responseData, responseData>(
    `flow/get-instance-list/?${queryParams.toString()}`
  );
};

export const getSystemPipelineAppsApi = (data: {
  page?: number;
  page_size?: number;
}) => {
  let queryParams = new URLSearchParams();

  if (data.page) queryParams.append("page", data.page?.toString());
  if (data.page_size)
    queryParams.append("page_size", data.page_size?.toString());

  return API.get<responseData, responseData>(
    `pipeline/get-system-pipeline-list/?${queryParams.toString()}`
  );
};

export const getPipelineAppsApi = () => {
  return API.get<responseData, responseData>(`pipeline/get-pipeline-list/`);
};

export const createPipelineAppsApi = (data: object) => {
  return API.post<responseData, responseData>(`pipeline/create/`, data);
};

export const updatePipelineAppsApi = (data: {
  pipelineId: number;
  data: object;
}) => {
  return API.put<responseData, responseData>(
    `pipeline/${data.pipelineId}`,
    data.data
  );
};

export const registerOrUpdatePipelineAppsApi = (data: object) => {
  return API.post<responseData, responseData>(
    `pipeline/register-or-update/`,
    data
  );
};

export const deletePipelineAppsApi = (data: { pipelineId: string }) => {
  return API.delete<responseData, responseData>(`pipeline/${data.pipelineId}`);
};

export const getPipelineRunsApi = (data: { versionId: number }) => {
  let queryParams = new URLSearchParams();
  queryParams.append("version_id", data.versionId?.toString());
  return API.get<responseData, responseData>(
    `run/list/?${queryParams.toString()}`
  );
};

export const getCurrentRunApi = async (data: { versionId: number }) => {
  let queryParams = new URLSearchParams();
  queryParams.append("version_id", data.versionId.toString()); // 确保versionId是有效的
  const response = await API.get<responseData, responseData>(
    `run/get-current-run/?${queryParams.toString()}`
  );

  if (response.success && response.data.message === "No current run found") {
    throw new Error("No current run found");
  } else {
    return response;
  }
};

export const getRunLogsApi = (data: { run_id: number }) => {
  return API.get<responseData, responseData>(`run/${data.run_id}/get-logs/`);
};

export const getAirbyteConnectionsApi = () => {
  return API.get<responseData, responseData>(`airbyte/connections/`);
};
