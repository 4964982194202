import React, {
  useEffect,
  useState,
  useMemo,
  forwardRef,
  useImperativeHandle,
  useRef,
} from "react";
import { Trans, useTranslation } from "react-i18next";
import {
  Button,
  Table,
  Typography,
  Tooltip,
  Modal,
  message,
  Form,
  Checkbox,
  Image,
  Dropdown,
  Input,
  Space,
} from "antd";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import type { ColumnsType } from "antd/es/table";
import type { SelectProps } from "antd";
import { Workspace, Membership, Role } from "../../types/Workspace";
import type { User } from "../../types/User";
import {
  ExclamationCircleOutlined,
  CaretDownOutlined,
  CaretUpOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import viewUtils from "../../utils/viewUtils";
import { updateWorkspace } from "../../store/account";
import {
  getUserListApi,
  uploadBatchAddUsersFileApi,
  downloadBatchAddUsersFileApi,
  getWorkspaceApi,
} from "../../api";
import usePermission from "../../utils/usePermission";
import { getConfig } from "../../config/config";
import Dragger from "antd/es/upload/Dragger";
import Upload, { RcFile } from "antd/es/upload";
import Add from "../../common/images/add.png";
import Excel from "../../common/images/excel.png";
import Failure from "../../common/images/failure.png";
import Success from "../../common/images/success.png";
import DeleteNormal from "../../common/images/delete_normal.png";
import DeleteHover from "../../common/images/delete_hover.png";
import Compact from "antd/es/space/Compact";

const config = getConfig();

enum BatchAddTab {
  FORM,
  FILE,
}

const unselectedButtonStyle = {
  flex: 1,
  height: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  fontWeight: 400,
  cursor: "pointer",
};
const selectedButtonStyle = {
  ...unselectedButtonStyle,
  borderRadius: 4,
  color: "#2A61FF",
  background: "white",
  fontWeight: 600,
};

const BatchAddMembershipModal = forwardRef(
  (
    props: {
      currentWorkspace: Workspace;
      updateWorkspace: (data: any) => void;
    },
    ref
  ) => {
    const { t, i18n } = useTranslation();
    const [showModal, setShowModal] = useState(false);
    const [activeTab, setActiveTab] = useState(BatchAddTab.FORM); //form or file

    const [newMemberships, setNewMemberships] = useState<Membership[]>([]);
    enum Status {
      INIT,
      FILE_SELECTED,
      UPLOAD_ERROR,
      UPLOAD_SUCCESS,
    }
    const [status, setStatus] = useState<Status>(Status.INIT);
    const [file, setFile] = useState<RcFile>();
    const [uploadResult, setUploadResult] = useState({
      href: "",
      download: "",
      success: 0,
      failure: 0,
    });

    const initModal = () => {
      setActiveTab(BatchAddTab.FORM);
      setNewMemberships([]);
      setStatus(Status.INIT);
      setFile(undefined);
      setUploadResult({
        href: "",
        download: "",
        success: 0,
        failure: 0,
      });
    };

    useImperativeHandle(ref, () => ({
      show: () => {
        setShowModal(true);
        initModal();
      },
    }));

    const handleCancel = () => {
      setShowModal(false);
      initModal();
    };

    const handleChangeTab = (value: BatchAddTab) => {
      setActiveTab(value);
    };

    const InviteFormTab = () => {
      const maxRecords = 100;
      const [form] = Form.useForm();

      const handleAdd = async () => {
        try {
          await form.validateFields();
        } catch (e) {
          return;
        }
        if (!props.currentWorkspace?.id) {
          message.error(t("workspace.tips.no_workspace"));
          return;
        }
        const inputAccount = form.getFieldsValue()?.username;

        const userAlreadyAdded =
          props.currentWorkspace?.memberships?.some(
            (item) => item.user.username === inputAccount
          ) ||
          props.currentWorkspace?.invitations?.some(
            (item) => item.invitee_account === inputAccount
          );
        if (userAlreadyAdded) {
          message.error(t("workspace.tips.member_is_existed"));
          return;
        }

        if (newMemberships.length === maxRecords) {
          message.error(
            t("workspace.tips.invitation_length", { num: maxRecords })
          );
          return;
        }

        setNewMemberships((data) => {
          if (data?.some((item) => item.user.username === inputAccount)) {
            return data;
          } else {
            return [
              {
                user: { username: inputAccount, name: inputAccount },
                role: Role.MEMBER,
              },
              ...data,
            ] as Membership[];
          }
        });

        form.resetFields();
      };

      const handleChangeRoleMenu = (key: any, membership: Membership) => {
        setNewMemberships(
          (data) =>
            data?.map((item) => {
              return item.user.username === membership.user.username
                ? {
                    ...item,
                    role: key,
                  }
                : item;
            }) || []
        );
      };

      const handleDelete = (membership: Membership) => {
        setNewMemberships(
          (data) =>
            data?.filter((item) => {
              return item.user.username !== membership.user.username;
            }) || []
        );
      };

      const handleInvite = () => {
        if (newMemberships.length === 0) {
          message.error(t("workspace.tips.no_user_add"));
          return;
        }
        props.updateWorkspace({
          workspace: {
            ...props.currentWorkspace,
            memberships: newMemberships,
          },
          success: (res: any) => {
            message.success(
              t("common.result_status", {
                title: t("workspace.actions.invite"),
                status: t("common.success"),
              })
            );
            handleCancel();
          },
          fail: (res: any) => {
            if (JSON.stringify(res.message).includes("Cannot find")) {
              message.error(t("workspace.tips.workspace_not_found"));
            } else {
              message.error(
                t("common.result_status", {
                  title: t("workspace.actions.invite"),
                  status: t("common.fail"),
                })
              );
            }
          },
        });
      };

      return (
        <div
          style={{ display: "flex", flexDirection: "column", height: "25em" }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              minHeight: 0,
              padding: "1em 0 5px 0",
            }}
          >
            <Compact>
              <Form form={form} layout="vertical" style={{ width: "100%" }}>
                <Form.Item
                  name="username"
                  style={{ marginBottom: 0 }}
                  rules={[
                    {
                      required: true,
                      message: t("common.required", {
                        title: t("workspace.account"),
                      }),
                    },
                  ]}
                >
                  <Input
                    placeholder={t("workspace.account")}
                    onPressEnter={handleAdd}
                  />
                </Form.Item>
              </Form>
              <Button type="primary" onClick={handleAdd}>
                {t("common.add")}
              </Button>
            </Compact>
          </div>
          <div style={{ flex: 1, overflowY: "auto" }}>
            {newMemberships?.map((membership: Membership) => (
              <div
                key={membership.user.username}
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginTop: "0.5em",
                  marginBottom: "0.5em",
                  marginRight: "0.5em",
                }}
              >
                <Typography.Text>
                  {`${viewUtils.prettifyUsername(membership.user.name)}`}
                </Typography.Text>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Dropdown
                    menu={{
                      items: [
                        {
                          key: Role.MEMBER,
                          label: t("workspace.member"),
                        },
                        {
                          key: Role.ADMIN,
                          label: t("workspace.admin"),
                        },
                      ],
                      onClick: ({ key }) =>
                        handleChangeRoleMenu(key, membership),
                    }}
                  >
                    <Space>
                      {membership.role === Role.ADMIN
                        ? t("workspace.admin")
                        : t("workspace.member")}
                      <CaretDownOutlined
                        style={{ color: "rgba(110,129,130)" }}
                      />
                    </Space>
                  </Dropdown>
                  <div
                    style={{
                      width: "1px",
                      height: "14px",
                      margin: "0px 10px",
                      background: "rgb(183,183,183)",
                    }}
                  />
                  <Button
                    type="text"
                    size="small"
                    onClick={() => handleDelete(membership)}
                    icon={
                      <CloseOutlined
                        style={{
                          color: "rgba(110,129,130)",
                          padding: 3,
                          fontSize: 11,
                        }}
                      />
                    }
                  />
                </div>
              </div>
            ))}
          </div>
          <div
            style={{ display: "flex", marginTop: 12, justifyContent: "end" }}
          >
            <Button onClick={handleCancel}>{t("common.cancel")}</Button>
            <Button
              type="primary"
              style={{ marginInlineStart: 8 }}
              onClick={handleInvite}
            >
              {t("workspace.actions.invite_button", {
                num: newMemberships.length,
              })}
            </Button>
          </div>
        </div>
      );
    };

    const InviteFileTab = () => {
      const maxSize = 10; //10M
      const maxRecords = 65536;
      const handleDownload = () => {
        downloadBatchAddUsersFileApi(
          props.currentWorkspace?.id ?? 0,
          i18n.resolvedLanguage ? i18n.resolvedLanguage : "en"
        ).then((res: any) => {
          const href = window.URL.createObjectURL(res.data);
          const link = document.createElement("a");
          link.href = href;
          link.setAttribute("download", res.headers["file-name"]);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        });
      };

      const beforeUpload = (file: RcFile) => {
        const isValid =
          file.type === "application/vnd.ms-excel" ||
          file.type ===
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
          file.type === "text/csv";
        if (!isValid) {
          message.error(t("workspace.tips.is_not_excel", { name: file.name }));
          return Upload.LIST_IGNORE;
        }
        if (file.size / 1024 / 1024 > maxSize) {
          message.error(t("workspace.tips.max_excel_size", { size: maxSize }));
          return Upload.LIST_IGNORE;
        }
        setFile(file);
        setStatus(Status.FILE_SELECTED);
        return Upload.LIST_IGNORE;
      };

      const handleDelete = () => {
        setFile(undefined);
        setStatus(Status.INIT);
      };

      const handleInvite = () => {
        if (!file) {
          message.error(t("workspace.tips.no_upload_file"));
          return;
        }
        const formData = new FormData();
        formData.append("files", file);
        formData.append("max-records", maxRecords + "");
        formData.append(
          "locale",
          i18n.resolvedLanguage ? i18n.resolvedLanguage : "en"
        );
        uploadBatchAddUsersFileApi(
          props.currentWorkspace?.id ?? 0,
          formData
        ).then((res) => {
          if (res.headers["error"]) {
            if (res.headers["error"].includes("Invalid excel")) {
              message.error(t("workspace.tips.invalid_excel"));
              return;
            }
          }
          setUploadResult({
            href: window.URL.createObjectURL(res.data),
            download: res.headers["file-name"],
            success: res.headers["success-num"],
            failure: res.headers["failure-num"],
          });
          if (res.headers["failure-num"] == 0) {
            setStatus(Status.UPLOAD_SUCCESS);
          } else {
            setStatus(Status.UPLOAD_ERROR);
          }
          props.updateWorkspace({
            workspace: {
              id: props.currentWorkspace?.id,
              name: props.currentWorkspace?.name,
            },
          });
        });
      };

      if (
        ![
          Status.INIT,
          Status.FILE_SELECTED,
          Status.UPLOAD_ERROR,
          Status.UPLOAD_SUCCESS,
        ].includes(status)
      ) {
        return null;
      }
      return (
        <div
          style={{ display: "flex", flexDirection: "column", height: "25em" }}
        >
          <div
            style={{
              flex: 1,
              ...(status != Status.INIT
                ? {
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    background: "rgba(0, 0, 0, 0.02)",
                  }
                : {}),
            }}
          >
            {status == Status.INIT && (
              <Dragger accept=".xls,.xlsx,.csv" beforeUpload={beforeUpload}>
                <p className="ant-upload-drag-icon">
                  <Image src={Add} preview={false} height={"32px"} />
                </p>
                <p className="ant-upload-text" style={{ fontSize: 14 }}>
                  {t("workspace.upload_file_method")}
                </p>
                <p className="ant-upload-hint">
                  {t("workspace.upload_file_format")}
                </p>
              </Dragger>
            )}
            {status == Status.FILE_SELECTED && (
              <>
                <Image src={Excel} preview={false} height={"32px"} />
                <p className="ant-upload-hint" style={{ marginTop: 16 }}>
                  {file?.name}
                </p>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Upload beforeUpload={beforeUpload}>
                    <Button type="link">
                      {t("workspace.actions.reupload")}
                    </Button>
                  </Upload>
                  <Button type="link" onClick={handleDelete}>
                    {t("common.delete")}
                  </Button>
                </div>
              </>
            )}
            {[Status.UPLOAD_SUCCESS, Status.UPLOAD_ERROR].includes(status) && (
              <>
                <Image
                  src={status == Status.UPLOAD_SUCCESS ? Success : Failure}
                  preview={false}
                  height={"32px"}
                />
                <div style={{ marginTop: 16 }}>
                  <Trans
                    i18nKey="workspace.import_result"
                    values={{
                      success: uploadResult.success,
                      failure: uploadResult.failure,
                    }}
                    components={{
                      success: <span style={{ color: "green" }} />,
                      failure: <span style={{ color: "red" }} />,
                      a:
                        status == Status.UPLOAD_SUCCESS ? (
                          <a style={{ display: "none" }} />
                        ) : (
                          <a
                            style={{ color: "#1677ff" }}
                            href={uploadResult.href}
                            download={uploadResult.download}
                          />
                        ),
                    }}
                  />
                </div>
              </>
            )}
          </div>
          <div style={{ marginTop: "1em" }}>
            <Trans
              i18nKey="workspace.import_rule"
              values={{
                size: maxSize,
                num: maxRecords,
              }}
              components={{
                p: <p style={{ color: "rgba(0,0,0,0.65)" }} />,
                a: <a style={{ color: "#1677ff" }} onClick={handleDownload} />,
              }}
            />
          </div>
          <div
            style={{
              width: "100%",
              display: "flex",
              marginTop: 12,
              justifyContent: "end",
            }}
          >
            {[Status.INIT, Status.FILE_SELECTED].includes(status) && (
              <Button onClick={handleCancel}>{t("common.cancel")}</Button>
            )}
            {status == Status.FILE_SELECTED && (
              <Button
                type="primary"
                style={{ marginInlineStart: 8 }}
                onClick={handleInvite}
              >
                {t("workspace.actions.invite")}
              </Button>
            )}
            {[Status.UPLOAD_SUCCESS, Status.UPLOAD_ERROR].includes(status) && (
              <Upload beforeUpload={beforeUpload}>
                <Button type="default">
                  {t("workspace.actions.continue_invite")}
                </Button>
              </Upload>
            )}
            {[Status.UPLOAD_SUCCESS, Status.UPLOAD_ERROR].includes(status) && (
              <Button
                type="primary"
                style={{ marginInlineStart: 8 }}
                onClick={handleCancel}
              >
                {t("common.finish")}
              </Button>
            )}
          </div>
        </div>
      );
    };

    if (!showModal) {
      return null;
    }
    return (
      <Modal
        open={!!showModal}
        title={t("workspace.actions.invite_member")}
        destroyOnClose
        onCancel={handleCancel}
        footer={null}
        centered={true}
      >
        <div
          style={{
            height: "3em",
            marginBottom: 10,
            padding: 5,
            background: "#0000000A",
            borderRadius: 4,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{
              ...(activeTab === BatchAddTab.FORM
                ? selectedButtonStyle
                : unselectedButtonStyle),
            }}
            onClick={() => handleChangeTab(BatchAddTab.FORM)}
          >
            {t("workspace.actions.invite")}
          </div>
          <div
            style={{
              ...(activeTab === BatchAddTab.FILE
                ? selectedButtonStyle
                : unselectedButtonStyle),
            }}
            onClick={() => handleChangeTab(BatchAddTab.FILE)}
          >
            {t("workspace.actions.batch_add")}
          </div>
        </div>
        {activeTab === BatchAddTab.FORM ? <InviteFormTab /> : <InviteFileTab />}
      </Modal>
    );
  }
);

const EditMembershipModal = forwardRef(
  (
    props: {
      currentWorkspace: Workspace;
      updateWorkspace: (data: any) => void;
    },
    ref
  ) => {
    const { t } = useTranslation();
    const [showItem, setShowItem] = useState<Membership | undefined>();
    const [form] = Form.useForm();

    useImperativeHandle(ref, () => ({
      show: (record: Membership) => {
        setShowItem(record);
        form.resetFields();
        form.setFieldsValue({
          id: record?.id,
          username: record?.user.username,
          name: record?.user.name,
          is_admin: record?.role === Role.ADMIN,
        });
      },
    }));

    const handleCancel = () => {
      setShowItem(undefined);
      form.resetFields();
    };

    const formSubmit = async () => {
      try {
        await form.validateFields();
      } catch (e) {
        return;
      }
      if (!props.currentWorkspace?.id) {
        message.error(t("workspace.tips.no_workspace"));
        return;
      }
      const data: {
        id?: number;
        username?: string;
        name?: string;
        is_admin?: boolean;
      } = form.getFieldsValue();
      props.updateWorkspace({
        workspace: {
          ...props.currentWorkspace,
          memberships: [
            {
              id: data.id,
              user: {
                username: data.username,
                name: data.name,
              },
              role: data.is_admin === true ? Role.ADMIN : Role.MEMBER,
            },
          ],
        },
        success: (res: any) => {
          message.success(
            t("common.result_status", {
              title: t("common.update"),
              status: t("common.success"),
            })
          );
          handleCancel();
        },
        fail: (res: any) => {
          if (JSON.stringify(res.message).includes("Cannot find")) {
            message.error(t("workspace.tips.workspace_not_found"));
          } else {
            message.error(
              t("common.result_status", {
                title: t("common.update"),
                status: t("common.fail"),
              })
            );
          }
        },
      });
    };

    if (!showItem) {
      return null;
    }
    return (
      <Modal
        open={!!showItem}
        title={t(`workspace.actions.update_member`)}
        okText={t("common.save")}
        cancelText={t("common.cancel")}
        centered={true}
        onCancel={handleCancel}
        onOk={formSubmit}
      >
        <Form
          className="membership-edit"
          layout="vertical"
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 28 }}
          form={form}
        >
          <Form.Item name="id" hidden>
            <Input />
          </Form.Item>
          <Form.Item
            label={t("workspace.account")}
            name="username"
            className="form-item-row"
          >
            <Input disabled />
          </Form.Item>
          <Form.Item
            label={t("workspace.username")}
            name="name"
            className="form-item-row"
          >
            <Input disabled={"invitee_account" in (showItem || {})} />
          </Form.Item>
          <Form.Item
            label={t("workspace.admin")}
            name="is_admin"
            valuePropName="checked"
            className="form-item-row"
          >
            <Checkbox />
          </Form.Item>
        </Form>
      </Modal>
    );
  }
);

const MembershipManagePage = (props: {
  user: User;
  currentWorkspace: Workspace;
  updateWorkspace: (data: any) => void;
}) => {
  const { t, i18n } = useTranslation();
  const permission = usePermission();
  const [currentWorkspaceMembers, setCurrentWorkspaceMembers] = useState<
    Membership[]
  >([]);
  const batchAddMembershipModalRef: any = useRef();
  const editMembershipModalRef: any = useRef();

  useEffect(() => {
    setCurrentWorkspaceMembers(
      props.currentWorkspace?.memberships
        ?.concat(
          props.currentWorkspace?.invitations?.map((item) => {
            return {
              ...item,
              user: {
                username: item.invitee_account,
                email: item.invitee_account,
                name: item.invitee_account,
              },
              type: "invitation",
            };
          })
        )
        ?.sort((a, b) => {
          return (b.created_at || "").localeCompare(a.created_at || "");
        }) || []
    );
  }, [props.currentWorkspace]);

  const columns: ColumnsType<Membership> = [
    {
      title: t("workspace.account"),
      key: "username",
      render: (r) => r.invitee_account ?? r.user.username,
    },
    {
      title: t("workspace.username"),
      key: "name",
      render: (r) => r.invitee_account ?? r.user.name,
      width: 200,
    },
    {
      title: t("workspace.created_at"),
      key: "created_at",
      render: (r) => viewUtils.utcToLocal(r.created_at),
    },
    {
      title: t("workspace.role"),
      key: "role",
      render: (r) => t(`workspace.${r.role.toLowerCase()}`),
    },
    {
      title: t("workspace.created_by_name"),
      key: "created_by_name",
      render: (r) =>
        r?.role === Role.OWNER ? (
          <div style={{ width: "100%", textAlign: "center" }}>-</div>
        ) : (
          <Tooltip title={r?.created_by?.username ?? r.operator?.username}>
            <div>{r?.created_by?.name ?? r.operator?.name}</div>
          </Tooltip>
        ),
    },
    {
      title: t("workspace.logined.title"),
      key: "logined",
      render: (r) =>
        t(`workspace.logined.${!r.invitee_account ? "active" : "inactive"}`),
    },
    {
      title: t("common.actions"),
      key: "actions",
      render: (r) => (
        <div>
          {permission.isAdmin && r.role !== Role.OWNER && (
            <>
              <Button size="small" type="link" onClick={() => handleEdit(r)}>
                {t("common.edit")}
              </Button>
              <Button
                size="small"
                type="link"
                onClick={() => handleDeleteMember(r)}
              >
                {t("common.remove")}
              </Button>
            </>
          )}
        </div>
      ),
    },
  ];

  const batchAddMembershipModal = useMemo(
    () => (
      <BatchAddMembershipModal
        ref={batchAddMembershipModalRef}
        currentWorkspace={props.currentWorkspace}
        updateWorkspace={props.updateWorkspace}
      />
    ),
    [props.currentWorkspace, props.updateWorkspace]
  );

  const editMembershipModal = useMemo(
    () => (
      <EditMembershipModal
        ref={editMembershipModalRef}
        currentWorkspace={props.currentWorkspace}
        updateWorkspace={props.updateWorkspace}
      />
    ),
    [props.currentWorkspace, props.updateWorkspace]
  );

  const handleEdit = (membership: Membership) => {
    !!props.currentWorkspace?.id &&
      !!editMembershipModalRef.current &&
      editMembershipModalRef.current.show(membership);
  };

  const handleDeleteMember = (membership: Membership) => {
    if (!props.currentWorkspace?.id) {
      return;
    }
    Modal.confirm({
      title: t("workspace.actions.delete_member"),
      icon: <ExclamationCircleOutlined />,
      content: t("workspace.tips.confirm_delete_member", {
        name:
          "invitee_account" in membership
            ? membership.invitee_account
            : membership.user.name,
      }),
      okText: t("common.ok"),
      cancelText: t("common.cancel"),
      centered: true,
      onOk: async () => {
        props.updateWorkspace({
          workspace: {
            ...props.currentWorkspace,
            memberships: [
              {
                ...membership,
                delete_flag: true,
              },
            ],
          },
          success: (res: any) => {
            message.success(
              t("common.result_status", {
                title: t("workspace.actions.delete_member"),
                status: t("common.success"),
              })
            );
          },
          fail: (res: any) => {
            if (JSON.stringify(res.message).includes("Cannot find")) {
              message.error(t("workspace.tips.workspace_not_found"));
            } else {
              message.error(
                t("common.result_status", {
                  title: t("workspace.actions.delete_member"),
                  status: t("common.fail"),
                })
              );
            }
          },
        });
      },
    });
  };

  return (
    <>
      {editMembershipModal}
      {batchAddMembershipModal}
      <div style={{ position: "relative", paddingTop: "1.5em" }}>
        <div className="tab-action" style={{ marginBottom: "1.5em" }}>
          {permission.isAdmin && (
            <Button
              type="primary"
              style={{ marginLeft: "1em" }}
              onClick={() => {
                !!props.currentWorkspace?.id &&
                  !!batchAddMembershipModalRef.current &&
                  batchAddMembershipModalRef.current.show();
              }}
            >
              {t("common.add_with_info", { title: t("workspace.member") })}
            </Button>
          )}
        </div>
        <Table
          columns={columns}
          pagination={{
            showSizeChanger: true,
            showQuickJumper: true,
            pageSizeOptions: [10, 20, 50],
            showTotal: (total) => t("common.total", { count: total }),
          }}
          dataSource={currentWorkspaceMembers}
          scroll={{ x: 900, scrollToFirstRowOnChange: true }}
        />
      </div>
    </>
  );
};

const mapStateToProps = (store: any) => ({
  user: store.account.user,
  currentWorkspace: store.account.currentWorkspace,
});

const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators(
    {
      updateWorkspace,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MembershipManagePage);
