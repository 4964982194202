import React, { useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import NavHyperLink from "../../components/NavHyperLink";
import { NavLink, useNavigate } from "react-router-dom";
import {
  Button,
  Space,
  Table,
  Typography,
  Tooltip,
  Modal,
  message,
  Pagination,
  Input,
  Image,
} from "antd";
import type { Dataset } from "../../types/Dataset";
import type { ColumnsType } from "antd/es/table";
import {
  getDatasetListApi,
  updateDatasetApi,
  deleteDatasetApi,
} from "../../api/DatasetApi";
import viewUtils from "../../utils/viewUtils";
import usePermission from "../../utils/usePermission";
import DatasetTemplateAction from "./DatasetTemplateAction";
import HeaderSearchIcon from "../../common/images/icons/header-search-icon.png";
import EditCell from "../../components/EditCell";
import { User } from "../../types/User";
import { connect } from "react-redux";
import { Workspace } from "../../types/Workspace";

const DatasetPage = (props: {
  currentUser?: User;
  currentWorkspace?: Workspace;
}) => {
  const { t } = useTranslation();
  const IsSimpleLayout = window?.location?.pathname.startsWith("/simple");

  const navigate = useNavigate();
  const permission = usePermission();
  const [datalist, setDatalist] = useState<{ data: Dataset[]; total: number }>({
    data: [],
    total: 0,
  });
  const [filter, setFilter] = useState({
    page: 1,
    page_size: 10,
    search: "",
  });
  const handlePageChange = (page: number, page_size: number) => {
    setFilter((data) => ({ ...data, page: page, page_size: page_size }));
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) =>
    setFilter((data) => ({ ...data, search: e.target.value, page: 1 }));

  useEffect(() => {
    if (!!permission?.username && !!props.currentWorkspace?.id) {
      getDatasetListApi({
        page: filter.page,
        page_size: filter.page_size,
        query: filter.search,
        ordering: "-created_at",
      }).then((res) => {
        if (res.success) {
          setDatalist({
            data: res.data,
            total: res.pagination?.count || 0,
          });
        }
      });
    }
  }, [filter, permission]);

  const columns: ColumnsType<Dataset> = [
    {
      key: "name",
      title: t("dataset.name"),
      width: 300,
      render: (r) => (
        <EditCell
          object={r}
          valueField={"name"}
          maxLength={50}
          isLink={true}
          disabled={r.owner !== props.currentUser?.username}
          handleCellSave={(value) => handleCellSave(r, value)}
        >
          <NavLink
            to={`${IsSimpleLayout ? "/simple" : ""}/dataset/data/${r?.id}`}
          >
            <Typography.Paragraph
              className="table-link"
              ellipsis={{
                rows: 1,
                tooltip: {
                  title: r.name,
                  placement: "bottomLeft",
                  overlayClassName: "description_tooltip",
                },
              }}
              style={{
                marginBottom: 0,
              }}
            >
              {r.name}
            </Typography.Paragraph>
          </NavLink>
        </EditCell>
      ),
    },
    {
      key: "description",
      title: t("common.description"),
      width: 300,
      render: (r) => (
        <Typography.Paragraph
          ellipsis={{
            rows: 1,
            tooltip: {
              placement: "bottomLeft",
              overlayClassName: "description_tooltip",
            },
          }}
          style={{ marginBottom: 0 }}
        >
          {r.description}
        </Typography.Paragraph>
      ),
    },
    {
      key: "owner",
      title: t("common.owner"),
      width: 120,
      render: (r) => (
        <Tooltip title={r.owner_user?.username}>
          {viewUtils.prettifyUsername(r.owner_user?.name)}
        </Tooltip>
      ),
    },
    {
      key: "created_at",
      width: 180,
      title: t("common.created_at"),
      render: (r) => viewUtils.prettifyDatatime(r.created_at),
    },
    {
      key: "updated_at",
      width: 180,
      title: t("common.updated_at"),
      render: (r) => viewUtils.prettifyDatatime(r.updated_at),
    },
    {
      key: "actions",
      title: t("common.actions"),
      fixed: "right",
      width: 230,
      render: (d: Dataset) => (
        <>
          <NavLink
            to={`${IsSimpleLayout ? "/simple" : ""}/dataset/data/${d?.id}`}
          >
            <Button size="small" type="link">
              {t("common.data")}
            </Button>
          </NavLink>
          {viewUtils.isDatasetAdmin(d, permission.username) && (
            <>
              <NavLink
                to={`${IsSimpleLayout ? "/simple" : ""}/dataset/info/${d.id}`}
              >
                <Button size="small" type="link">
                  {t("common.edit")}
                </Button>
              </NavLink>
              <Button size="small" type="link" onClick={() => handleCopy(d)}>
                {t("common.copy")}
              </Button>
              <Button size="small" type="link" onClick={() => handleDelete(d)}>
                {t("common.delete")}
              </Button>
            </>
          )}
        </>
      ),
    },
  ];

  const handleCopy = (dataset: Dataset) => {
    Modal.confirm({
      title: t("dataset.tips.confirm_copy"),
      content: t("dataset.tips.confirm_copy_desc"),
      centered: true,
      onOk() {
        navigate(
          `${IsSimpleLayout ? "/simple" : ""}/dataset/create/${dataset.id}`
        );
      },
    });
  };

  const handleDelete = (dataset: Dataset) => {
    Modal.confirm({
      title: t("dataset.tips.confirm_delete"),
      content: (
        <Trans
          i18nKey="dataset.tips.confirm_delete_desc"
          values={{
            dataset_name: dataset.name,
          }}
          components={{
            p: <p />,
          }}
        />
      ),
      centered: true,
      onOk() {
        deleteDatasetApi({ datasetId: Number(dataset.id) }).then((res) => {
          if (res.success) {
            message.success(
              t("common.result_status", {
                title: t("common.delete"),
                status: t("common.success"),
              })
            );
            setFilter((data) => ({ ...data }));
          } else {
            message.error(
              t("common.result_status", {
                title: t("common.delete"),
                status: t("common.fail"),
              })
            );
          }
        });
      },
    });
  };

  const handleCellSave = (dataset: Dataset, value: string) => {
    updateDatasetApi({
      datasetId: Number(dataset.id),
      data: { ...dataset, name: value },
    }).then((res) => {
      if (res.success) {
        message.success(
          t("common.result_status", {
            title: t("common.save"),
            status: t("common.success"),
          })
        );
        setFilter((data) => ({ ...data }));
      } else {
        message.error(
          t("common.result_status", {
            title: t("common.save"),
            status: t("common.fail"),
          })
        );
      }
    });
  };

  return (
    <>
      <NavHyperLink items={[{ title: t("dataset.title") }]} />
      <div className="layout-content-box">
        <div className="page-header-actions">
          <div>
            <Input
              value={filter?.search}
              className="list-search-input"
              placeholder={`${t("dataset.name")}、${t(
                "common.description"
              )}、${t("common.owner")}`}
              onChange={handleSearch}
              suffix={
                <Image
                  src={HeaderSearchIcon}
                  preview={false}
                  style={{ width: 15, height: 15 }}
                />
              }
            />
          </div>
          <Space size={10}>
            <DatasetTemplateAction />
            <NavLink to={`${IsSimpleLayout ? "/simple" : ""}/dataset/create`}>
              <Button type="primary">
                {t("common.add_with_info", { title: t("dataset.title") })}
              </Button>
            </NavLink>
          </Space>
        </div>
        <div>
          <Table
            tableLayout="fixed"
            className="common-table"
            rowKey={(r) => r?.id || ""}
            scroll={{ x: 1310, scrollToFirstRowOnChange: true }}
            columns={columns}
            dataSource={datalist.data}
            pagination={false}
          />
          <Pagination
            showQuickJumper
            hideOnSinglePage={!datalist.data?.length}
            showTotal={(total) => t("common.total", { count: total })}
            current={filter.page}
            pageSize={filter.page_size}
            total={datalist.total}
            onChange={handlePageChange}
            showSizeChanger
            pageSizeOptions={[10, 20, 50]}
            style={{
              textAlign: "right",
              justifyContent: "flex-end",
              marginTop: "1em",
            }}
          />
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (store: any) => ({
  currentUser: store.account.user,
  currentWorkspace: store.account.currentWorkspace,
});

export default connect(mapStateToProps, {})(DatasetPage);
