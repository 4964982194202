import { useState } from "react";
import { useDispatch } from "react-redux";
import { initToken } from "../api/BaseApi";
import { getCurrentWorkspaceApi, getWorkspaceListApi, getMeApi } from "../api";
import { initAccountInfo, setCurrentWorkspace } from "../store/account";
import { Workspace } from "../types/Workspace";
import { User } from "../types/User";
import AuthService from "./AuthService";
import useNovu from "./useNovu";

type AppState = {
  status: string;
  message?: string;
};

const useInitApp = () => {
  const dispatch = useDispatch();
  const novu = useNovu();
  const [appState, setAppState] = useState<AppState>({ status: "loading" });
  const IsSimpleLayout = window?.location?.pathname.startsWith("/simple");

  if (appState.status === "loading") {
    const token = AuthService.token() || "";
    let workspaces: Workspace[] | [];
    let currentUser: User;
    initToken(token);
    setAppState({ status: "logined" });
    getMeApi({ simple: IsSimpleLayout })
      .then((res) => {
        if (res?.success) {
          currentUser = res.data;
          return getWorkspaceListApi();
        } else {
          setAppState({
            status: "error",
            message: "服务器异常，请稍后刷新重试",
          });
        }
      })
      .then((res) => {
        if (res?.success) {
          workspaces = res.data;
          return getCurrentWorkspaceApi();
        } else {
          setAppState({
            status: "error",
            message: "服务器异常，请稍后刷新重试",
          });
        }
      })
      .then((res) => {
        let currentWorkspace: Workspace | null = null;
        if (!res?.success && res?.code !== 404) {
          setAppState({
            status: "error",
            message: "服务器异常，请稍后刷新重试",
          });
        } else {
          if (
            res?.success &&
            !!workspaces.find(
              (workspace: Workspace) => workspace.id === res?.data?.id
            )
          ) {
            currentWorkspace = res?.data;
          } else if (res?.code === 404) {
            if (workspaces?.length > 0) {
              dispatch(
                setCurrentWorkspace({
                  id: workspaces[0].id,
                }) as any
              );
              currentWorkspace = workspaces[0];
            }
          }
          if (
            currentUser?.enterprise_mode &&
            IsSimpleLayout &&
            currentWorkspace?.space_type !== "SIMPLEDEMO"
          ) {
            const simpleDemoWorkspace = workspaces.find(
              (workspace: Workspace) => workspace.space_type === "SIMPLEDEMO"
            );
            if (simpleDemoWorkspace) {
              dispatch(
                setCurrentWorkspace({
                  id: simpleDemoWorkspace.id,
                }) as any
              );
              currentWorkspace = simpleDemoWorkspace;
            }
          }
          try {
            const searchParams = new URLSearchParams(window.location.search);
            const workspaceId = searchParams.get("workspace_id");
            if (
              !!workspaceId &&
              parseInt(workspaceId) !== currentWorkspace?.id
            ) {
              const workspace = workspaces.find(
                (workspace: Workspace) => workspace.id === parseInt(workspaceId)
              );
              if (!!workspace?.id) {
                dispatch(
                  setCurrentWorkspace({
                    id: workspace.id,
                  }) as any
                );
                currentWorkspace = workspace;
              }
            }
          } catch (e) {
            console.log(e);
          }
          dispatch(
            initAccountInfo({
              user: {
                username: currentUser?.username || "",
                name: currentUser?.name || "",
                email: currentUser?.email || "",
                is_superuser: currentUser?.is_superuser || false,
                enterprise_mode: currentUser?.enterprise_mode || false,
                notification_info: currentUser?.notification_info || "",
              },
              token: token,
              workspaces: workspaces,
              currentWorkspace: currentWorkspace,
            })
          );
          novu.init(currentUser);
          setAppState({ status: "logined" });
        }
      });
  }

  return appState;
};

export default useInitApp;
